








































































































































































































































































































































































































































































































































































































.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
        background: var(
            --header-1,
            linear-gradient(90deg, #69eacb 0%, #eaccf8 48%, #6654f1 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .search-member {
        max-width: 467px;
        height: 48px;
        width: 100%;
        border-radius: 128px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        gap: 10px;

        input {
            outline: none;
            border: none;
            height: 40px;
            width: 90%;
            border-radius: 12px;
            background: rgba(141, 141, 141, 0.29);
            color: #fff;

            &:focus {
                box-shadow: none;
            }
        }

        button {
            border-radius: 100vw;
            background: linear-gradient(50deg, #5a41bd 14.98%, #d629f2 106.07%);
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            display: flex;
            gap: 5px;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            height: 42px;
            background: rgba(141, 141, 141, 0.29);
            // opacity: 0.5;
            border-radius: 12px;
            outline: none;
            box-shadow: none;
        }

        .copy {
            background-image: linear-gradient(
                50deg,
                #5a41bd 14.98%,
                #d629f2 106.07%
            );
        }

        svg {
            margin-right: 5px;
        }

        input {
            outline: none;
            border: 1px solid #b625f9;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }

        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            margin-left: 2em;
            margin-right: auto;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }

    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #a6a6a6;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: rgba(62, 18, 96, 0.46);
                    &:nth-child(odd) {
                        background: rgba(62, 18, 96, 1);
                    }

                    td {
                        background: none;
                        height: 100%;
                        padding: 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
        &.mobile {
            tbody {
                tr {
                    td {
                        .details {
                            padding: 0.5rem;
                            .address {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                background-color: #86198f;
                                color: #fff;
                                border-radius: 7px;
                                transition: 0.2s;
                                &:active {
                                    background-color: #4a044e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-statistical {
        margin: 35px auto;
        border-radius: 22px;
        border: 1px solid #8500af;
        background: var(
            --13434,
            linear-gradient(
                50deg,
                rgba(90, 65, 189, 0.2) 14.98%,
                rgba(214, 41, 242, 0.2) 106.07%
            )
        );
        backdrop-filter: blur(44.29999923706055px);

        .table-item {
            border-radius: 1px;
            min-height: 75px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Poppins';
            justify-content: space-between;

            p {
                height: 100%;
                margin-bottom: 0;
                width: 95%;
                display: flex;
                align-items: center;
            }

            .left,
            .right {
                padding: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .left {
                p {
                    padding-left: 2rem;
                }
            }

            .right {
                p {
                    justify-content: center;
                    color: #b625f9;
                }
            }

            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                min-height: none;
                height: auto;
                padding: 5px 0;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                min-height: none;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
        .myRef {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 8px 15px;
            @media (min-width: 992px) {
                padding: 0px;
            }
            .refItem {
                text-transform: uppercase;
                background-image: linear-gradient(
                    50deg,
                    #5a41bd 14.98%,
                    #d629f2 106.07%
                );
                border-radius: 100vw;
                padding: 2px 12px;
                font-family: 'HHBI';
                color: #e4e4e7;
                span {
                    color: white;
                    font-size: 1.2em;
                }
            }
        }
    }

    .box-statistical {
        // background: linear-gradient(to right, #18d4e2, #a506f9);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 140px;
        height: 100%;
        outline: none;
        width: 100%;
        max-width: 427px;
        padding: 15px;
        position: relative;
        margin: 0 auto;
        border-radius: 22px;
        border: 1px solid #8500af;
        background: var(
            --13434,
            linear-gradient(
                50deg,
                rgba(90, 65, 189, 0.2) 14.98%,
                rgba(214, 41, 242, 0.2) 106.07%
            )
        );
        backdrop-filter: blur(44.29999923706055px);

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            color: #ffffff;
            z-index: 1;

            .text-title {
                font-size: 1.4em;
                line-height: 120%;
                font-style: italic;
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
            }

            .text-content {
                font-size: 1.4em;
                line-height: 120%;
                color: #b625f9;
                font-weight: 700;
            }
        }
    }
}
