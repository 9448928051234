


























































































































































































.UCON.bg-main-container {
    background: linear-gradient(270deg, #300537 0%, #02005f 100%);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    isolation: isolate;
}

.UCON.bg-main-container::after {
    content: '';
    position: fixed;
    z-index: -1;
    background-image: url('~@/assets/images/background/main-bg.png');
    background-repeat: no-repeat;
    background-size: 230% auto;
    background-position: bottom center;
    inset: 0;
    opacity: 0.23;
}

main.content {
    min-height: calc(100vh - 90px);
    height: 100%;
    padding: 20px 0;
    width: 100%;
    margin: 0;
    @media (max-width: 991px) {
        min-height: calc(100vh - 60px);
    }
    @media (max-width: 575px) {
        min-height: calc(100vh);
        padding-bottom: 80px;
    }
}
