











































































































































































































































































































































.activity-tab {
    label {
        font-family: 'Poppins' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        color: #00ffff !important;
    }
    .date-form {
        .form-control {
            background: transparent !important;
        }
    }
    .min-200 {
        min-width: 150px;
        width: max-content;
    }
    .group-hist {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
        padding-right: 5px;
        position: relative;
        .select-type {
            max-width: 275px;
            height: 48px;
            text-transform: capitalize;
        }
        .b-form-datepicker,
        select {
            max-width: 275px;
            height: 40px;
            background: rgba(33, 41, 48, 0.6);
            color: #fff;
            border-radius: 100px;
            border: none;
            option {
                background: #00000b;
            }

            button {
                &#datepicker,
                &#datepicker-2 {
                    color: #fff;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    color: #dcdcdc;
                }
            }
            label.form-control {
                color: #fff !important;
                display: flex;
                align-items: center;
            }
        }
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 7px;
            bottom: 0;
            top: 0;
            margin: auto;
            display: none;
        }
    }

    .bede-btn {
        max-width: 221px;
        height: 50px;
    }
    .refresh {
        color: #000;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        width: 40px;
        height: 40px;
        padding: 0;
        border: 1px solid #00ffff;
        border-radius: 100%;
        @media (max-width: 575px) {
            width: 35px;
            height: 35px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .filter-history {
        flex: 1;
        // max-width: 255px;
        min-width: 220px;
        @media (max-width: 575px) {
            max-width: 300px;
            min-width: 100%;
        }
    }

    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(105% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #a6a6a6;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: rgba(62, 18, 96, 0.46);
                    &:nth-child(odd) {
                        background: rgba(62, 18, 96, 1);
                    }

                    td {
                        background: none;
                        height: 100%;
                        padding: 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
        &.mobile {
            tbody {
                tr {
                    td {
                        .details {
                            padding: 0.5rem;
                            .address {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                background-color: #86198f;
                                color: #fff;
                                border-radius: 7px;
                                transition: 0.2s;
                                &:active {
                                    background-color: #4a044e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .table-statistical {
        // border: 1px solid #00ffff;
        max-width: 500px;
        margin: 15px auto;
        .table-item {
            // background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Helvetica';
            justify-content: space-between;

            &:nth-of-type(even) {
                box-shadow: inset 0px 0px 2005px rgba(0, 255, 255, 0.2);
            }
            .left {
                flex: 0 0 calc(100% - 300px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .right {
                flex: 0 0 250px;
                border-left: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: 992px) {
                    justify-content: flex-start;
                    text-align: left;
                    padding-left: 5%;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
}
