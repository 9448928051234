@keyframes loop {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

// reset css

button {
    background: none;
    border: none;
    outline: none;
    box-shadow: unset;
}

.bede-btn {
    // max-width: 221px;
    width: 100%;
    // height: 50px;
    border-radius: 0;
    border: none;
    outline: unset;
    position: relative;
    cursor: pointer;
    background: linear-gradient(to right, #18d4e2, #a506f9);

    &:hover {
        background-size: 200%;
        animation: loop 1s infinite linear;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 1px;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            #353f48;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(14px, 1.5vw, 20px);
        line-height: 150%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #B625F9;
        z-index: 10;
    }
}

.read-form {
    background: rgba(0, 255, 255, 0.2) !important;
}

.form-control {
    border-radius: 12px;
    background: rgba(141, 141, 141, 0.29);
    box-shadow: 0px 0px 4px 0px rgba(188, 188, 188, 0.25);
    backdrop-filter: blur(18.799999237060547px);
}


// global css main color
.main-text-color {
    color: #B625F9;
}

.bg-input {
    background: rgba(0, 255, 255, 0.15) !important;
    backdrop-filter: blur(2px) !important;
}

.active-children {
    color: #B625F9 !important;
}

.main-bg {
    background: rgba(0, 255, 255, 0.2) !important;
}
