@charset "utf-8";
@font-face {
    font-family: "HHBI";
    src: url("~@/assets/Font/hemi\ head\ bd\ it.otf") format("opentype");
}
@font-face {
    font-family: "helvetica-neue-regular";
    src: url("~@/assets/Font/helvetica-neue-regular.ttf") format("opentype");
}

@font-face {
    font-family: "helvetica";
    src: url("~@/assets/Font/Helvetica.ttf") format("opentype");
}

@font-face {
    font-family: "hemiheadRg-BoldItalic";
    src: url("~@/assets/Font/hemi head bd it.otf") format("opentype");
}
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600;1,700&display=swap');