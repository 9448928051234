.auth-box {
    border-radius: 40px;
    border-top: 1.5px solid #db00ff;
    border-right: 1.5px solid #db00ff;
    border-bottom: 1.5px solid #db00ff;
    background: linear-gradient(
        105deg,
        rgba(43, 0, 135, 0) -3.07%,
        rgba(90, 12, 126, 0.38) 49.04%,
        rgba(0, 0, 0, 0.63) 97.84%
    );
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(319.8500061035156px);
    width: 100%;
    max-width: 625px;
    min-height: 325px;
    padding: 0 35px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;

    .form-group {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        // margin: 1.75rem auto 1.75rem !important;
        text-align: left;
        width: 100%;

        a {
            color: #b625f9;
            font-size: 17px;
            font-weight: 500;
        }

        label {
            color: #00ffa9;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
        }

        input {
            border-radius: 10px;
            background: rgba(141, 141, 141, 0.29);
            box-shadow: 0px 0px 4px 0px rgba(188, 188, 188, 0.25);
            //backdrop-filter: blur(18.799999237060547px);
            color: #fff;
            font-feature-settings: 'clig' off, 'liga' off;
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            line-height: 26px;
            padding: 0 10px;
            outline: none !important;
            border: 0 !important;

            &::-webkit-input-placeholder {
                color: #a6a6a6;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }

        button {
            border-radius: 100vw;
            background: transparent;
            border: 1px solid #fff;
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            color: #ffffff;
            width: 100%;
            padding: 8px 10px;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: normal;
            text-transform: uppercase;
            @media (min-width: 768px) {
                font-size: 18px;
            }

            &:hover {
                background: linear-gradient(
                    50deg,
                    #5a41bd 14.98%,
                    #d629f2 106.07%
                );
            }
        }
    }

    .note {
        text-align: center;

        .title {
            color: #b625f9;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        p {
            color: #fff;
            font-weight: 500;

            span {
                color: #b625f9;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }

    .text-more {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        margin: 15px auto;
        display: flex;
        flex-direction: column;
        text-align: center;

        a {
            color: #b625f9;
            font-weight: 500;
            display: block;
            @media (min-width: 375px) {
                margin-left: 10px;
            }
        }

        @media (min-width: 480px) {
            flex-direction: row;
            margin-left: 10px;
        }
    }

    .breaking {
        margin: 30px auto;
        background: linear-gradient(00deg, #00ffa9 0%, #00b1ff 100%);
        height: 2px;
        width: 100%;
    }
}

.more-info {
    .text-more {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        a {
            background: linear-gradient(
                90deg,
                #69eacb 70.83%,
                #eaccf8 85.4%,
                #6654f1 101.19%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            @media (min-width: 375px) {
                margin-left: 10px;
            }
        }

        @media (min-width: 480px) {
            flex-direction: row;
            margin-left: 10px;
        }
    }

    .breaking {
        margin: 30px auto;
        background: linear-gradient(00deg, #00ffa9 0%, #00b1ff 100%);
        height: 2px;
        width: 100%;
    }
}
