



















.auth-page {
    padding: calc(5vh + 20px) 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}
