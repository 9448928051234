









































































































































































































































































.flip-clock {
    text-align: left;
    perspective: 600px;
    margin: 0 auto;
    padding: 0;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
}

.flip-clock__piece {
    display: inline-block;
    margin: 0 0.2vw;
    @media (min-width: 1000px) {
        margin: 0 5px;
    }
}

.flip-clock__slot {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    text-align: center;
    color: #ffff;
    text-shadow: 2px 0 0 rgba(182, 37, 249, 0.5),
        2px 0 0 rgba(182, 37, 249, 0.5), 2px 2px 0 rgba(182, 37, 249, 0.5),
        2px -2px 0 rgba(182, 37, 249, 0.5), 2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px 2px 2px rgba(182, 37, 249, 0.5);
}

$halfHeight: 0.72em;
$borderRadius: 0.15em;
.flip-card {
    display: block;
    position: relative;
    padding-bottom: $halfHeight;
    font-size: 2.25rem;
    line-height: 0.95;
}

@media (min-width: 1000px) {
    .flip-clock__slot {
        font-size: 1.2rem;
    }
    .flip-card {
        font-size: 3rem;
    }
}

.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
    display: block;
    height: $halfHeight;
    color: #ffff;
    text-shadow: 2px 0 0 rgba(182, 37, 249, 0.5),
        2px 0 0 rgba(182, 37, 249, 0.5), 2px 2px 0 rgba(182, 37, 249, 0.5),
        2px -2px 0 rgba(182, 37, 249, 0.5), 2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px 2px 2px rgba(182, 37, 249, 0.5);
    background: #291e67;
    padding: 0.23em 0.15em 0.4em;
    border-radius: $borderRadius $borderRadius 0 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 2.1em;
    height: $halfHeight;
}

.flip-card__top-4digits,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits,
.flip-card__back-4digits::before,
.flip-card__back-4digits::after {
    display: block;
    height: $halfHeight;
    color: #ffff;
    text-shadow: 2px 0 0 rgba(182, 37, 249, 0.5),
        2px 0 0 rgba(182, 37, 249, 0.5), 2px 2px 0 rgba(182, 37, 249, 0.5),
        2px -2px 0 rgba(182, 37, 249, 0.5), 2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px 2px 2px rgba(182, 37, 249, 0.5);
    background: #291e67;
    padding: 0.23em 0.15em 0.4em;
    border-radius: $borderRadius $borderRadius 0 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 2.65em;
    height: $halfHeight;
}

.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
    color: #ffff;
    text-shadow: 2px 0 0 rgba(182, 37, 249, 0.5),
        2px 0 0 rgba(182, 37, 249, 0.5), 2px 2px 0 rgba(182, 37, 249, 0.5),
        2px -2px 0 rgba(182, 37, 249, 0.5), 2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px -2px 2px rgba(182, 37, 249, 0.5),
        2px 2px 2px rgba(182, 37, 249, 0.5);
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px rgba(255, 255, 255, 0.37);
    background: #291e67;
    border-radius: 0 0 $borderRadius $borderRadius;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
}

.flip-card__back-bottom,
.flip-card__back-bottom-4digits {
    z-index: 1;
}

.flip-card__bottom::after,
.flip-card__back-bottom::after,
.flip-card__bottom-4digits::after,
.flip-card__back-bottom-4digits::after {
    display: block;
    margin-top: -$halfHeight;
}

.flip-card__back::before,
.flip-card__bottom::after,
.flip-card__back-bottom::after,
.flip-card__back-4digits::before,
.flip-card__bottom-4digits::after,
.flip-card__back-bottom-4digits::after {
    content: attr(data-value);
}

.flip-card__back,
.flip-card__back-4digits {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
}

.flip-card__back::before,
.flip-card__back-4digits::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.flip .flip-card__back::before,
.flip .flip-card__back-4digits::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
}

.flip .flip-card__bottom,
.flip .flip-card__bottom-4digits {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }
    0%,
    99% {
        opacity: 1;
    }
    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {
    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }
    51% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg);
        z-index: 5;
    }
}
