

























































































































































.metamaskLogin {
    border-radius: 100vw;
    border: 1px solid var(--text-1, #fff);
    box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
    width: 100%;
    text-align: center;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    padding: 8px 10px;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        font-size: 18px;
    }

    img {
        margin-right: 5px;
        height: 25px;
        width: 25px;
    }

    &:hover {
        background: linear-gradient(50deg, #5a41bd 14.98%, #d629f2 106.07%);
        box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
    }
}
